import { KeyboardEvent, useState } from "react";
// -- Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

//Internal

//-- Types
import { Actor, IActorResumeUploadRequest } from "../../../../store/model/actorModel";
import { State } from "../../../../model/address";
import Gender, { genderOptions } from "../../../../model/Gender";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { filterDataSelector } from "../../../../store/selectors/filterSelectors";
import { isUpdatingActorSelector } from "../../../../store/selectors/actorSelector";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import { preventNonNumericInput, separatorHandler } from "../../../../utility/input";
import DeleteIcon from "../../../../assets/components/DeleteIcon";
import { useTheme } from "styled-components";
import { MuiFileInput } from "mui-file-input";
import { apiUploadActorResume } from "../../../../store/async/actorAsync";
import { actorResumeDelete, actorResumeUpload } from "../../../../store/actions/actorActions";
import config from "../../../../config";
import SearchableDropDownField from "../../SearchableDropDownField";

interface EditActorPersonalDetailsProps {
    actor: Actor;
    updateActorObject: (actor: Actor) => void;    
    submittedInvalidFields?: string[];
}

interface PersonalDetails {
    emailAddress: string;
    firstName: string;
    middleName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    religionId: number;
    addressLine: string;
    suburb: string;
    stateId: State;
    postCode: number;
    mobilePhone: string;
    homePhone: string;
    workPhone: string;
    emergencyContact: string;
    emergencyContactRelationship: string;
    emergencyContactNumber: string;
    description: {
        vaccinationStatusId: number;
        occupationId: number;
    };
    hasVisa: boolean;
    visaType: string;
}

const EditActorPersonalSection: React.FC<EditActorPersonalDetailsProps> = ({
    actor,
    updateActorObject,    
    submittedInvalidFields = [],
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const filterData = useAppSelector(filterDataSelector);
    const isUpdating = useAppSelector(isUpdatingActorSelector);
    const user = useAppSelector(loggedInUserSelector);

    const actorLinkTypes = ["IMDB", "YouTube", "Other"];

    const [file, setFile] = useState<File|null>(null);
    const [resumeFileError, setResumeFileError] = useState<string>("");

    const handleFieldUpdate = (key: keyof Actor, value: any) => {
        updateActorObject({ ...actor, [key]: value });
    };

    const handleResumeChange = (newFile: File | null) => {
        if(newFile === null) {
            return;
        }

        if(newFile && newFile?.type !== "application/pdf"){
            setResumeFileError("Please upload a PDF file.");
            setTimeout(() => {
                setResumeFileError("");
            }, 5000);
            return;
        }

        if(newFile && newFile.size > 5000000){
            setResumeFileError("Max file size 5mb");
            setTimeout(() => {
                setResumeFileError("");
            }, 5000);
            return;
        }
        
        dispatch(actorResumeUpload({actorId: actor.id, actorResumeFile: newFile} as IActorResumeUploadRequest));
    }

    function handlePhoneUpdate(value: string, isMobile: boolean = false) {
        var newVal = value.replace(/\s/g, "");
        if(isMobile || value.startsWith("04")) {            
            if(newVal.length > 4) {
                newVal = newVal.substring(0, 4) + " " + newVal.substring(4, newVal.length);
            }
            if(newVal.length > 8){
                newVal = newVal.substring(0, 8) + " " + newVal.substring(8, newVal.length);
            }
        } else {
            if(newVal.length > 2) {
                newVal = newVal.substring(0, 2) + " " + newVal.substring(2, newVal.length);
            }
            if(newVal.length > 7){
                newVal = newVal.substring(0, 7) + " " + newVal.substring(7, newVal.length);
            }
        }

        return newVal;
    }

    return (
        <>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="firstName"
                        label="First Name"
                        className="mb-2"
                    >
                        <Form.Control
                            name="firstName"
                            type="text"
                            placeholder="text"
                            value={actor.firstName ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate("firstName", e.target.value);                                
                            }}                      
                            isInvalid={submittedInvalidFields.includes(
                                "firstName"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
                <Col md>
                    <FloatingLabel
                        controlId="middleName"
                        label="Middle Name"
                        className="mb-2"
                    >
                        <Form.Control
                            name="middleName"
                            type="text"
                            placeholder="text"
                            value={actor.middleName ?? ""}
                            onChange={(e) =>
                                handleFieldUpdate("middleName", e.target.value)
                            }                            
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
                <Col md>
                    <FloatingLabel
                        controlId="lastName"
                        label="Surname"
                        className="mb-2"
                    >
                        <Form.Control
                            name="lastName"
                            type="text"
                            placeholder="text"
                            value={actor.lastName ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate("lastName", e.target.value);                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "lastName"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="gender"
                        label="Gender"
                        className="mb-2"
                    >
                        <Form.Select
                            name="gender"
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "gender",
                                    parseInt(e.currentTarget.value, 10)
                                );
                            }}                            
                            value={actor.gender ?? -1}
                            isInvalid={submittedInvalidFields.includes(
                                "gender"
                            )}
                            disabled={isUpdating}
                        >
                            <option key={-1} value="-1">
                                Select gender
                            </option>
                            {genderOptions.map((gender, index) => {
                                return (
                                    <option key={index} value={gender.value}>
                                        {gender.label}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="religionId"
                        label="Religion"
                        className="mb-2"
                    >
                        <Form.Select
                            name="religionId"
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "religionId",
                                    parseInt(e.currentTarget.value, 10)
                                );
                            }}                            
                            value={actor.religionId ?? -1}
                            isInvalid={submittedInvalidFields.includes(
                                "religionId"
                            )}
                            disabled={isUpdating}
                        >
                            <option key={-1} value="-1">
                                Select Religion
                            </option>
                            {filterData.religions.map((religion, index) => {
                                return (
                                    <option key={index} value={religion.id}>
                                        {religion.description}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            { user?.role === "Admin" &&
                <Row>
                    <Col md>        
                        <FloatingLabel
                            controlId="dateOfBirth"
                            label="Date of Birth"
                            className="mb-2"
                        >
                            <Form.Control
                                type="date"
                                name="dateOfBirth"
                                placeholder="01/01/2000"
                                value={actor.dateOfBirth ?? ""}
                                onChange={(e) =>
                                    handleFieldUpdate("dateOfBirth", e.target.value)
                                }
                                isInvalid={submittedInvalidFields.includes(
                                    "dateOfBirth"
                                )}
                                disabled={isUpdating}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
            }
            
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="emailAddress"
                        label="Email Address"
                        className="mb-2"
                    >
                        <Form.Control
                            name="emailAddress"
                            type="email"
                            placeholder="email@domain.com"
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "emailAddress",
                                    e.target.value
                                );                                
                            }}                        
                            value={actor.emailAddress ?? ""}
                            isInvalid={submittedInvalidFields.includes(
                                "emailAddress"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>

            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="vaccinationStatus"
                        label="Vaccination Status"
                        className="mb-2"
                    >
                        <Form.Select
                            name="vaccinationStatus"
                            value={actor.description.vaccinationStatusId ?? 0}
                            placeholder="Double vaxed"
                            onChange={(e) => {
                                handleFieldUpdate("description", {
                                    ...actor.description,
                                    vaccinationStatusId: parseInt(e.currentTarget.value, 10),
                                    vaccinationStatus: filterData.vaccinationStatuses.find(x => x.id === parseInt(e.currentTarget.value, 10))
                                });
                            }}                          
                            isInvalid={submittedInvalidFields.includes(
                                "description.vaccinationStatusId"
                            )}
                            disabled={isUpdating}
                        >
                            <option key={-1} value={-1}>
                                Select vaccination status
                            </option>
                            {filterData.vaccinationStatuses.map((status) => (
                                <option key={status.id} value={status.id}>
                                    {status.description}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <Form.Label>Current Occupation:</Form.Label>
                    <SearchableDropDownField
                        name="professionId"
                        placeholder="Current Occupation"
                        options={filterData.occupations.map((occupation) => {
                            return {
                                value: occupation.id,
                                label: occupation.description
                            }
                        })}
                        value={actor.description.currentOccupationId ?? -1}
                        onChange={(newValue) =>
                            handleFieldUpdate("description", {
                                ...actor.description,
                                currentOccupationId: parseInt(newValue?.value ?? "-1", 10),
                                occupation: filterData.occupations.find(x => x.id === parseInt(newValue?.value ?? "-1", 10))
                            })
                        }
                        disabled={isUpdating}
                    />
                </Col>
            </Row>
            {user?.role === "Admin" &&
                <Row>
                    <Col md>
                        <FloatingLabel
                            controlId="performanceLevel"
                            label="Performance Level"
                            className="mb-2"
                        >
                            <Form.Select
                                name="performanceLevel"
                                placeholder="Bit Part"
                                value={actor.description.performanceLevelId ?? -1}
                                onChange={(e) =>
                                    handleFieldUpdate("description", {
                                        ...actor.description,
                                        performanceLevelId: parseInt(e.currentTarget.value, 10),                                    
                                    })
                                }
                                disabled={isUpdating}
                            >
                                <option value="-1" key="-1">Select a Performance Level</option>
                                {filterData.performanceLevels.map((perfLevel) => (
                                    <option
                                        key={perfLevel.id}
                                        value={perfLevel.id}
                                    >
                                        {perfLevel.description}
                                    </option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
            
            }
            
            <Row>
                <Col md>
                    <FloatingLabel
                        label="Has a Working visa?"
                        controlId="hasVisa"
                        className="mb-2"
                    >
                        <Form.Select
                            placeholder="placeholder"
                            name="hasVisa"                            
                            value={actor.hasVisa ? "1" : "0"}
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "hasVisa",
                                    e.target.value === "0" ? false : true
                                );
                            }}
                            disabled={isUpdating}
                        >
                            <option key="0" value="0">
                                No
                            </option>
                            <option key="1" value="1">
                                Yes
                            </option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="visaType"
                        label="Working Visa Type"
                        className="mb-2"
                    >
                        <Form.Control
                            name="visaType"
                            placeholder="Visa"
                            value={actor.hasVisa ? actor.visaType ?? "" : ""}
                            onChange={(e) => {
                                handleFieldUpdate("visaType", e.target.value);                                
                            }}                            
                            disabled={!actor.hasVisa || isUpdating}
                            isInvalid={submittedInvalidFields.includes(
                                "visaType"
                            )}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Form.Label className="mt-3">
                <b>Address</b>
            </Form.Label>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="addressLine"
                        label="Street Address"
                        className="mb-2"
                    >
                        <Form.Control
                            name="addressLine"
                            type="text"
                            placeholder="123 street street"
                            value={actor.addressLine ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "addressLine",
                                    e.target.value
                                );                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "addressLine"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="suburb"
                        label="Suburb"
                        className="mb-2"
                    >
                        <Form.Control
                            type="text"
                            name="suburb"
                            placeholder="Testington"
                            value={actor.suburb ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate("suburb", e.target.value);                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "suburb"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
                <Col md>
                    <FloatingLabel
                        controlId="state"
                        label="State"
                        className="mb-2"
                    >
                        <Form.Select
                            name="state"
                            value={actor.stateId ?? -1}
                            onChange={(e) =>
                                handleFieldUpdate("stateId", e.target.value)
                            }
                            disabled={isUpdating}
                        >
                            <option key="-1" value={-1}>
                                Select State
                            </option>
                            {Object.keys(State)
                                .filter((x) => isNaN(parseInt(x, 10)))
                                .map((x, index) => (
                                    <option key={index} value={index}>
                                        {x}
                                    </option>
                                ))}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col md>
                    <FloatingLabel
                        controlId="postcode"
                        label="Postcode"
                        className="mb-2"
                    >
                        <Form.Control
                            name="postcode"
                            placeholder="XXXX"
                            type="number"
                            max={9999}
                            value={actor.postCode ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate("postCode", e.target.value);                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "postCode"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>                
            </Row>
            <Form.Label className="mt-3">
                <b>Contact</b>
            </Form.Label>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="mobileNumber"
                        label="Mobile Phone"
                        className="mb-2"
                    >
                        <Form.Control
                            name="mobileNumber"
                            type="text"
                            placeholder="xxxxxxxxxx"
                            value={actor.mobilePhone ?? ""}
                            onChange={(e) => {
                                var newPhone = handlePhoneUpdate(e.target.value, true)
                                handleFieldUpdate(
                                    "mobilePhone",
                                    newPhone
                                );                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "mobilePhone"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="homeNumber"
                        label="Home Number"
                        className="mb-2"
                    >
                        <Form.Control
                            name="homeNumber"
                            type="text"
                            placeholder="xxxxxxxxxx"
                            value={actor.homePhone ?? ""}
                            onChange={(e) => {
                                var newPhone = handlePhoneUpdate(e.target.value)
                                handleFieldUpdate("homePhone", newPhone);                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "homePhone"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="workNumber"
                        label="Work Number"
                        className="mb-2"
                    >
                        <Form.Control
                            name="workNumber"
                            type="text"
                            placeholder="xxxxxxxxxx"
                            value={actor.workPhone ?? ""}
                            onChange={(e) => {
                                var newPhone = handlePhoneUpdate(e.target.value)
                                handleFieldUpdate("workPhone", newPhone);                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "workPhone"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Form.Label className="mt-3">
                <b>Bank Account Details</b>
            </Form.Label>
            <Row className="mb-3">
                <Col md>
                    <FloatingLabel
                        controlId="bankAccountName"
                        label="Bank Account Name"
                    >
                        <Form.Control
                            name="bankAccountName"
                            placeholder="James Smith"
                            value={actor.bankAccountName}
                            onChange={(e) => {
                                handleFieldUpdate("bankAccountName", e.target.value);                                
                            }}                                    
                            isInvalid={submittedInvalidFields.includes("bankAccountName")}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row  className="mb-3">
                <Col md>
                    <FloatingLabel
                        controlId="bankAccountNumber"
                        label="Bank Account Number"
                    >
                        <Form.Control
                            name="bankAccountNumber"
                            placeholder="James Smith"
                            maxLength={10}
                            value={actor.bankAccountNumber}
                            onKeyDown={(e) => preventNonNumericInput(e as KeyboardEvent<HTMLInputElement>)}                            
                            onChange={(e) => {
                                handleFieldUpdate("bankAccountNumber", e.target.value);                                
                            }}                                        
                            isInvalid={submittedInvalidFields.includes("bankAccountNumber")}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md>
                    <FloatingLabel
                        controlId="bankAccountBsb"
                        label="BSB Number"
                    >
                        <Form.Control
                            name="bsbNo"
                            maxLength={7}
                            placeholder="James Smith"
                            value={actor.bsbNo}
                            onKeyDown={(e) => {
                                preventNonNumericInput(e as KeyboardEvent<HTMLInputElement>)
                                separatorHandler(e as KeyboardEvent<HTMLInputElement>, "-", 3, true)
                            }}                            
                            onChange={(e) => {                                
                                handleFieldUpdate("bsbNo", e.target.value);                                
                            }}
                            isInvalid={submittedInvalidFields.includes("bsbNo")}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Form.Label className="mt-3">
                <b>Emergency Contact</b>
            </Form.Label>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="emergencyContact"
                        label="Emergency Contact Name"
                        className="mb-2"
                    >
                        <Form.Control
                            name="emergencyContact"
                            placeholder="Testing testington"
                            value={actor.emergencyContact ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "emergencyContact",
                                    e.target.value
                                );                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "emergencyContact"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="emergencyContactRelationship"
                        label="Emergency Contact Relationship"
                        className="mb-2"
                    >
                        <Form.Control
                            name="emergencyContact"
                            placeholder="something"
                            value={actor.emergencyContactRelationship ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "emergencyContactRelationship",
                                    e.target.value
                                );                
                            }}                        
                            isInvalid={submittedInvalidFields.includes(
                                "emergencyContactRelationship"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="emergencyContactNumber"
                        label="Emergency Contact Number"
                        className="mb-2"
                    >
                        <Form.Control
                            name="emergencyContactNumber"
                            placeholder="xxxxxxxxxx"
                            value={actor.emergencyContactNumber ?? ""}
                            onChange={(e) => {
                                handleFieldUpdate(
                                    "emergencyContactNumber",
                                    e.target.value
                                );                                
                            }}                            
                            isInvalid={submittedInvalidFields.includes(
                                "emergencyContactNumber"
                            )}
                            disabled={isUpdating}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <div className="d-flex justify-content-between">
                <Form.Label className="mt-3">
                    <b>Industry-related materials</b>
                    <p>Please provide a link to any current show reels / acting credits you may have.</p>
                </Form.Label>
                {(actor.actorLinks ?? []).length < 4 &&
                    <div 
                        style={{ fontSize: "2rem" }}
                        onClick={() => updateActorObject(
                            { ...actor, 
                                actorLinks: [...actor.actorLinks, 
                                    {
                                        actorLinkId: (actor.actorLinks.length + 1) * -1, 
                                        actorId: actor.id, 
                                        link: "", 
                                        linkTitle: actorLinkTypes.filter(linkTitle =>  linkTitle === "Other" || actor.actorLinks.findIndex(x => x.linkTitle === linkTitle) === -1)[0] 
                                    }
                                ]
                            }
                        )}
                    >
                        +
                    </div>
                }
            </div>
            <Row>
                <Col md>
                    {(actor.actorLinks ?? []).length === 0 && <p>No Links added</p>}
                    {actor.actorLinks && actor.actorLinks.map((x, index) => {
                        return <div className="mb-3 d-flex gap-3 align-items-center" key={index}>
                            <Form.Select
                                name="linkTitle"
                                value={x.linkTitle}
                                onChange={(e) => {
                                    var temp = [...actor.actorLinks];
                                    var itemIndex = temp.findIndex(y => y === x);
                                    temp[itemIndex] = { ...x, linkTitle: e.currentTarget.value};
                                    updateActorObject({...actor, actorLinks: temp});
                                }}
                            >          
                                {actorLinkTypes.map(linkType => 
                                    <option key={linkType} value={linkType}>{linkType}</option>    
                                )} 
                                
                                                                                 
                            </Form.Select>
                            <Form.Control 
                                name="link"
                                value={x.link}
                                onChange={(e) => {
                                    var temp = [...actor.actorLinks];
                                    var itemIndex = temp.findIndex(y => y === x);
                                    temp[itemIndex] = { ...x, link: e.currentTarget.value};
                                    updateActorObject({...actor, actorLinks: temp});
                                }}
                            />
                            <div onClick={(e) => {
                                var temp = [...actor.actorLinks];
                                temp = temp.filter(y => y.actorLinkId !== x.actorLinkId);
                                updateActorObject({...actor, actorLinks: temp});
                            }}>
                                <DeleteIcon fill={theme.palette.error.main} style={{ width: "1.5rem", height: "1.5rem"}} />
                            </div>
                        </div>
                    })}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label><b>Resume / CV</b> (PDF files, 5mb max)</Form.Label><br/>
                    {actor.actorResume !== null && actor.actorResume !== undefined && <div className="mb-3 mt-3 d-flex justify-content-between align-items-center">
                            <div>
                                <b>current:</b> <a href={config.api.BASE_URL + "/actor/resume/" + actor.id}>{actor.actorResume.resumeFileName}</a>
                            </div>
                            <div onClick={(e) => dispatch(actorResumeDelete({ actorResumeId: actor.actorResume!.actorResumeId }))}>
                                <DeleteIcon 
                                    style={{ width: "2rem", height: "2rem"}} 
                                    fill={theme.palette.error.main}                                 
                                />
                            </div>
                        </div>
                    }
                    <MuiFileInput className="w-100 mb-3" value={file} onChange={(newFile) => handleResumeChange(newFile)} placeholder={"Upload " + (actor.actorResume ? "" : "new ") + "resume"} />
                    {resumeFileError.length > 0 &&
                        <Alert className="mt-3" variant="danger">{resumeFileError}</Alert>
                    }
                </Col>
            </Row>
        </>
    );
};

export default EditActorPersonalSection;
